@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Mono&family=Roboto+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

$primary-font: 'Montserrat', san-serif;
$secondary-font: 'Roboto Serif', serif; 
$primary-color: #D3997A;
$overlay-primary-color: #D3997A50;
$secondary-color: #A24C4A;
$text-light: #F1F4EE;
$text-dark: #40353A;
$accent-color: #A48C76;
$primary-color-70: #D3997A70;
$secondary-color-70: #A24C4A70;

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $primary-font;
    color: $text-dark;
}

//-------> Typography <-------//

h1, h2 {
    font-family: $secondary-font;
}

h1 {
    font-weight: 700;
}

h2 {
    font-weight: 500;
}


a {
    color: inherit;
    text-decoration-color: $secondary-color;
    text-decoration-thickness: 5px;
}

a:hover,
a:focus {
    color: $secondary-color;
}

.highlight {
    padding: 2px;
    background: linear-gradient(180deg, #ffffff00 60%, $secondary-color-70 60%);
}

//-------> Buttons <-------//

.btn-outline-secondary {
    color: $secondary-color;
    border: 2px solid $secondary-color;
}

.btn-outline-secondary:hover {
    background-color: $secondary-color;
    border: 2px solid $secondary-color;
}

.alt-btn-color {
    border: 2px solid $text-light;
    color: $text-light;
    background-color: $secondary-color;
}

.alt-btn-color:hover {
    background-color: $text-light;
    border-color: $secondary-color;
    color: $secondary-color;
}

//-------> Containers <-------//

.container {
    margin: 0 auto;
    max-width: 2000px;
}

.section {
    padding: 3rem 2rem;
}

.section-alt-colors {
    background-color: $primary-color;
    color: $text-light;
}


.section-header {
    position: relative;
    text-align: center;
    margin-bottom: 4rem;
    size: 3rem;
    text-transform: lowercase;
}

.section-header::after {
    content: " ";
    width: 50%;
    height: 1px;
    background-color: $secondary-color;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 50%;
    border-radius: 3px;
}



.main-header {
    background-color: $primary-color;
    padding: 10px 0;

    .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        border: 4px solid $text-light;
        border-radius: 50%;
        background-color: $secondary-color;
        color: $text-light;
        padding: 10px;
        font-size: 1.25rem;
        font-weight: 500;
        cursor: pointer;
    }

    .logo:hover {
        opacity: 90%;
    }

    ul {
        display: flex;
        gap: 1rem;
        margin: 0;

        li { 
            list-style: none;

            a {
                text-decoration: none;
                color: $text-light;
                font-size: 1.2rem;
            }

        }
    }
}

.link-icon-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: center;
    position: fixed;
    top: 1rem;
    left: 1rem;

    a {
        color: $text-dark;
    }
}

@media (max-width: 600px) {
    .link-icon-row {
        flex-direction: row;
        background: $primary-color;
        opacity: 80%;
        width: 100vw;
        top: 0;
        left: 0;
        padding: 10px 0;
        justify-content: space-around;
        z-index: 1000;
    }
}

.connect-icon {
    font-size: 1.5rem;
}

.connect-icon:hover,
.connect-icon:focus {
    color: $secondary-color;
}

