@use "../../styles/app.scss";

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero::before {
    content: " ";
    width: 100%;
    height: 100%;
    background-image: url('../../../public/images/background.jpg');
    background-position: center;
    background-size: cover;
    opacity: 25%;
    z-index: -1;
    position: absolute;
}

.hero-content {
    margin: 1rem;
}

.hero-text {
    h1 {
        font-size: clamp(2rem, 4.5vw, 4rem);
        color: app.$secondary-color;
    }
    h2 {
        font-size: clamp(1.7rem, 4vw, 3rem);
    }

    p {
        font-size: clamp(1rem, 3vw, 1.3rem);
    }
}

.link-icon-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: center;
    position: fixed;
    top: 1rem;
    left: 1rem;

    a {
        color: app.$text-dark;
    }
}

@media (max-width: 600px) {
    .link-icon-row {
        flex-direction: row;
        background: app.$primary-color;
        width: 100vw;
        top: 0;
        left: 0;
        padding: 10px 0;
        justify-content: space-around;
        z-index: 1000;
    }
}

.connect-icon {
    font-size: 1.5rem;
}

.connect-icon:hover,
.connect-icon:focus {
    color: app.$secondary-color;
}

.hero-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
    justify-content: center;
}

