@use '../../styles/app';

.about {

    .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 800px;
        gap: 3rem;
        margin: 0 auto;
    }

    .about-text {
        width: 50%;
    }

    .about-img {
        // border: 3px solid app.$secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
        z-index: 2;
        box-shadow: -2px -3px 15px #00000090;

        img {
            height: 300px;
            width: 300px;
            z-index: 3;
        }
    }

    .shadow-outline {
        position: relative;
    }

    .shadow-outline::after {
        content: " ";
        position: absolute;
        height: 100%;
        width: 100%;
        border: 5px solid app.$secondary-color;
        border-radius: 5px;
        bottom: 1.2rem;
        right: 1.2rem;
        z-index: 1;
    }

    @media (max-width: 795px) {
        .about-text {
            width: 100%;
        }

        .inner {
            justify-content: center;
        }
    }
    @media (max-width: 374px) {
        .about-img {
            display: none;
        }

        .img-container::after {
            display: none;
        }
    }
}

