@use "../../styles/app.scss";

.contact

.contact-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.alt-btn {
    padding: 10px 20px;
    width: 10rem;
}